.app-container {
    display: flex;
    height: 100vh;
    transition: background-color 0.3s ease;
    position: relative; 
  }
  
  .app-sidebar {
    background-color: #047BC4; 
    color: white;
    transition: width 0.3s ease, left 0.3s ease; 
    position: fixed; 
    height: 100%; 
    top: 0; 
    left: 0; 
    width: 0; 
    overflow: hidden;
    z-index: 2000;
  
    &.visible {
      width: 200px; 
    }
  
   /* Sidebar brand container */
.sidebar-brand {
display: flex;
align-items: center;
justify-content: center;
transition: all 0.1s ease; /* Smooth resizing transition */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease; /* Smooth resizing transition */
}


  .sidebar-nav {
    flex: 1;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-top: 10px;

      li {
        display: flex;
        align-items: right;
        padding:5px;
        transition: background-color 0.2s ease;
        margin-bottom: 10px;
        width: 95%;
        


        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }

        a {
          color: black;
          text-decoration: none;
          width: 100%;
          display: flex;
          align-items: center;

          &:hover {
            color: #007bff;
          }
        }
      }
    }
  }

  .logout-container {
    padding: 265px 15px;

    a {
      color: white;
      display: flex;
      align-items: center;

      i {
        margin-right: 10px;
      }

      &:hover {
        color: #ff4136;
      }
    }
  }

}

// .header {
//   height: 60px; 
//   background-color: #007bff; 
//   color: white; 
//   display: flex;
//   align-items: center; 
//   justify-content: space-between; 
//   padding: 0 20px; 
//   margin: 0; 
//   flex-shrink: 0; 
//   z-index: 1000;
// }

// .footer {
//   height: 40px;
//   color: white; 
//   display: flex;
//   align-items: center; 
//   justify-content: center; 
//   padding: 0; 
//   margin: 0;
//   flex-shrink: 0; 
//   z-index: 1000; 
// }

