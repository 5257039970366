.dashboard-container {
  background-color: ghostwhite;
  margin-bottom: 40px;
  text-align: center;
  
}

.dashboard-title {
  background-color: #007acc;
  color: white;
  font-size: 14px;
  align-content: center;
  height: 40px;
  margin-top: 2px;
  font-weight: 400;
}

.dashboard-subtitle {
  border: 2px solid #007acc;
  padding: 5px;
  border-radius: 15px;
  margin-bottom: 15px;
  text-align: center;
  font-size: 16px;  
  color: #007acc;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
}

.speedometer {
display: flex;
justify-content: center;
min-width: 200px;
margin-bottom: -60px;
}

.speedometer1 {
justify-content: center;
margin-bottom: -180px;
}


.widgets-container {
display: flex;
justify-content: space-evenly;
gap: 15px;  
width: 100%;
max-width: 1500px; 
margin: 0 auto; 
padding: 20px 0;
}

.widget {
flex: 0 0 180px; 
padding: 10px;  
background: linear-gradient(145deg, #6a11cb, #2575fc);
border-radius: 12px;  /* Slightly rounded corners */
text-align: center;
font-size: 14px;  /* Smaller font size */
font-weight: 600;  /* Slightly bold text */
color: white;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* Light shadow */
cursor: pointer;
transition: all 0.3s ease;
position: relative;
overflow: hidden;
background: linear-gradient(145deg, #ff6f61, #d63a3a); /* Default color */
}

.widget h4 {
font-size: 18px;  /* Smaller font size for title */
margin-bottom: 10px;
font-weight: 800;  /* Bold text */
letter-spacing: 1px;  /* Letter spacing for readability */
text-transform: uppercase;
color: white;
}

.widget p {
font-size: 14px;  /* Smaller font size for description */
margin-top: 10px;
font-weight: 500;  /* Semi-bold text */
letter-spacing: 0.5px;
color: #fff;
opacity: 0.8;
transition: color 0.3s ease;
}

/* Hover Effects for Widgets */
.widget:hover {
box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); /* Hover shadow */
transform: scale(1.05); /* Slightly scale up on hover */
}

.widget .widget-icon {
font-size: 24px;
margin-bottom: 8px;
position: absolute;
top: 15px;
left: 50%;
transform: translateX(-50%);
color: #fff;
}

/* Horizontal Bar and Other Elements */
.horizontal-bar-container {
margin-top: 30px;
display: flex;
justify-content: center;
}

.horizontal-bar {
display: flex;
width: 87%;
height: 30px;  /* Reduced height */
border-radius: 10px;
overflow: hidden;
margin-left: 30px;
}

.bar-segment {
display: flex;
align-items: center;
justify-content: center;
color: white;
font-weight: bold;
font-size: 12px;  /* Smaller font size */
}

.kW {
background-color: #007acc;
color: white;
flex: 1;
}

.kVAR {
background-color: #e0e0e0;
color: #333;
flex: 1;
}

.total-value {
font-weight: bold;
color: #333;
padding: 5px 15px;
border-radius: 1px;
font-size: 12px;
}


.horizontal-bar:hover {
box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); 
transform: scale(1.00); 

}

.gauge-chart-container {
display: flex;
justify-content: space-between;
padding: 20px;
}

.gauge-card {
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
background-color: #fff;
border: 1px solid #ccc;
border-radius: 10px;
margin-left: -20px;
margin-right: -20px;
width:33%

}

h4 {
font-size: 18px;
font-weight: bold;
color: #000000;
}

.table-container {
margin-top: 20px;

h4 {
    font-size: 1rem; 
    margin-bottom: 10px;
    font-weight: bold;
}

.sub-meters-table {
    border-collapse: collapse;
    font-size: 0.85rem;  
    width:100%;
    margin-bottom:15px;

    thead {
        background-color: #469beb;
        color: white;

        th {
            padding: 8px;
            border-bottom: 2px solid #ddd;
            font-weight: bold;
            text-align: center;
        }
    }

    tbody {
        tr {
            &:nth-child(even) {
                background-color: #f9f9f9;
            }
        }

        td {
            padding: 8px;
            border-bottom: 1px solid #ddd;
        }
    }

    tfoot {
        font-weight: bold;

        td {
            padding: 8px;
            background-color: #f4f4f4;
            border-top: 2px solid #ddd;
        }
    }
}
}


/* Responsive Design */
@media (max-width: 1200px) {
.widgets-container {
    flex-direction: column;
    gap: 15px;
    margin: 0 auto; /* Keep widgets centered */
}

.widget {
    width: 100%;
    padding: 10px;
}
}

@media (max-width: 768px) {
.dashboard-title {
    font-size: 14px;
    height: 35px;
}

.dashboard-subtitle {
    font-size: 16px;
    margin-left: 20px;
    width: 100%;
}

.widgets-container {
    flex-direction: column;
    gap: 15px;
    margin: 0 auto; /* Keep widgets centered */
}

.widget {
    width: 100%;
    padding: 10px;
}

.table-container {
    margin-left: 0;
}

.sub-meters-table {
    font-size: 0.75rem;
}
}

@media (max-width: 480px) {
.dashboard-title {
    font-size: 12px;
    height: 30px;
}

.dashboard-subtitle {
    font-size: 14px;
    margin-left: 10px;
    width: 100%;
}

.widgets-container {
    flex-direction: column;
    gap: 10px;
    margin: 0 auto; /* Keep widgets centered */
}

.widget {
    width: 100%;
    padding: 8px;
}

.table-container {
    margin-left: 0;
}

.sub-meters-table {
    font-size: 0.65rem;
}
}

.table-container {
margin-top: 10px;
padding: 20px;
background-color: #ffffff;
width: 80%;
margin-left: 20px;
}

/* Header of the table */
.table-header {
font-size: 16px;
font-weight: bold;
margin-bottom: 30px;
}

/* Table structure */
.table {
width: 100%;
border-collapse: collapse;
}

/* Table row styling */
.table-row {
border-bottom: 1px solid #ddd;
}

/* Table cell styling */
.table-cell {
padding: 5px;
font-size: 18px;
}

.table .table-row:last-child {
border-bottom: none;
}
.table-wrapper {
overflow-x: auto;
-webkit-overflow-scrolling: touch; /* For smooth scrolling on mobile */
max-width: 100%; /* Ensures the table takes the full width of the container */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.pagination-controls button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.pagination-controls button:disabled {
    background-color: #cacaca;
    cursor: not-allowed;
}

.pagination-controls button:not(:disabled):hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.pagination-controls span {
    font-size: 14px;
    color: black;
}