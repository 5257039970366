.dashboard {
    background-color: ghostwhite;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;

    .heading {
        background-color: #007acc;
        color: white;
        font-size: 14px;
        height: 20px;
        display: flex;
        justify-content: center; /* Centers content horizontally */
        align-items: center; /* Centers content vertically */
        margin-top: 2px;
    }

    .content-container {
        display: flex;
        flex-direction: column;
        max-width: 1200px; /* Limit the width */
        margin: 0 auto; /* Center the container */

        .card {
            margin-bottom: 20px;
            padding: 15px;
            border: none;
            border-radius: 5px;
            width: 90%; /* Limit the width */
            margin: 0 auto; /* Center horizontally */
        }
        .machines-container {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .group-card {
                display: flex;
                    flex-direction: column; /* Stack the content vertically */
                    align-items: center;
                    justify-content: center; /* Center items horizontally */
                    margin: 10px 0;
                    border-radius: 5px;
                    background-color:white;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                    width: 100%; /* Ensure the width stretches to 100% of its container */

                // .sub-card {
                //     display: flex;
                //     flex-direction: column; /* Stack the content vertically */
                //     align-items: center;
                //     justify-content: center; /* Center items horizontally */
                //     margin: 10px 0;
                //     border-radius: 5px;
                //     background-color: #f9f9f9;
                //     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                //     width: 100%; /* Ensure the width stretches to 100% of its container */

                    .heading-with-border {
                        border: 2px solid #007acc;
                        padding: 5px;
                        border-radius: 20px;
                        text-align: center; /* Center text */
                        font-size: 20px;
                        color: #007acc;
                        margin: 0 auto; /* Center the element itself */
                        width: 900px;
                    }

                    .item {
                        flex: 1;
                        margin: 0 10px;
                        text-align: center; /* Center text within each item */
                    }
                }
            }
        }
    }

    .widget-card {
        &:hover {
            transform: scale(1.05);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
        }
    }

    .widgetss {
        flex: 0 0 180px; 
        padding: 10px;  
        background: linear-gradient(145deg, #6a11cb, #2575fc);
        border-radius: 12px;  /* Slightly rounded corners */
        text-align: center;
        font-size: 14px;  /* Smaller font size */
        font-weight: 600;  /* Slightly bold text */
        color: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* Light shadow */
        // cursor: pointer;
        transition: all 0.3s ease;
        position: relative;
        overflow: hidden;
      }

      .widgetss:nth-child(1) {
        background: linear-gradient(145deg, #ff6f61, #d63a3a); /* Purple to Blue */
    }
    
    .widgetss:nth-child(2) {
        background: linear-gradient(145deg, #673ab7, #512da8); /* Coral to Red */
    }
    
    .widgetss:nth-child(3) {
        background: linear-gradient(145deg, #4caf50, #2b9e36); /* Green to Light Green */
    }
    
    .widgetss:nth-child(4) {
        background: linear-gradient(145deg, #2196f3, #0b7dda); /* Orange to Deep Orange */
    }
    
    .widgetss:nth-child(5) {
        background: linear-gradient(145deg, #f57c00, #e65100); /* Blue to Sky Blue */
    }
    
    .widgetss:nth-child(6) {
        background: linear-gradient(145deg, #9c27b0, #7b1fa2); /* Crimson to Dark Purple */
    }
    
    .widgetss:nth-child(7) {
        background: linear-gradient(145deg, #ffc107, #ff9800); /* Red to Yellow */
    }
    
    .widgetss:nth-child(8) {
        background: linear-gradient(145deg, #8bc34a, #689f38); /* Violet to Amber */
    }

    .table-spinner {
        border: 2px solid #f3f3f3;
        border-top: 2px solid #3498db; 
        border-radius: 50%;
        width: 12px;
        height: 12px;
        animation: spin 1s linear infinite;
        display: inline-block;
        margin-right: 5px;
    }