.dashboard {
    background-color: ghostwhite;
    margin: 50px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .main-container {
        width: 1000px;
        max-width: 1200px;
        background: #ffffff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;

        .nav-bar {
            display: flex;
            justify-content: space-around;
            margin-bottom: 20px;
            background-color:#007acc; 
            padding: 10px;
            border-radius: 8px;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); 

        
            button {
                padding: 10px 10px;
                cursor: pointer;
                border: none;
                background-color: #f0f0f0; 
                border-radius: 5px;
                font-size: 10px;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 1px; 
                transition: background-color 0.3s, box-shadow 0.3s; 
        
                &.active {
                    background-color: #0056b3; 
                    color: white;
                    box-shadow: 0 2px 4px rgba(0, 86, 179, 0.3); /* Box shadow on active */
                }
        
                &:hover {
                    background-color: #004494;
                    box-shadow: 0 3px 8px rgba(0, 70, 119, 0.3); 
                }
        
                &:focus {
                    outline: none; 
                    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); 
                }
            }
        }
        

        .hourly-report,
        .daily-report,
        .daily-report-production,
        .log-report
        .hourly-report {
            .controls-card {
              padding: 16px;
              background-color: #f9f9f9;
              border-radius: 8px;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
              margin-bottom: 20px;
              display: flex;
              align-items: center;
              gap: 20px;
          
              .select-dropdown {
                padding: 10px;
                border: 1px solid #007bff;
                border-radius: 8px;
                font-size: 14px;
                color: #333;
                background-color: #f9f9f9;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                transition: border-color 0.3s ease;
                width: 230px;
          
                &:hover {
                  border-color: #0056b3;
                }
              }

              
          
              input[type="datetime-local"] {
                padding: 10px;
                border: 1px solid #007bff;
                border-radius: 8px;
                font-size: 14px;
                color: #333;
                background-color: #f9f9f9;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                transition: border-color 0.3s ease;
                width: 200px;
          
                &:focus {
                  border-color: #0056b3;
                  outline: none;
                }
              }
          
              button {
                padding: 6px 15px;
                height: 36px; 
                cursor: pointer;
                border: none;
                background-color: #007acc;
                color: white;
                border-radius: 4px;
                display: flex;
                align-items: center;
                transition: background-color 0.3s;
                font-size: 12px;
          
                .excel-icon {
                  margin-right: 5px;
                }
          
                &:hover {
                  background-color: #0056b3;
                }
              }
            }
          
            .table-container {
              padding: 15px;
              background: #fff;
              border-radius: 8px;
              border: 1px solid #ccc;
              width: 90%;
              margin: 0 auto;
          
              .table-title {
                margin-bottom: 10px;
                padding: 8px;
                background-color: #007acc;
                color: white;
                text-align: center;
                border-radius: 4px;
                font-weight: bold;
                border: 1px solid #007bff;
              }
          
              table {
                width: 100%;
                border-collapse: collapse;
          
                th,
                td {
                  padding: 12px;
                  border: 1px solid #ccc;
                  text-align: center;
                  font-size: 12px;
                }
          
                thead th {
                  background-color: #007acc;
                  color: white;
                  font-weight: bold;
                }
          
                tfoot td {
                  font-weight: bold;
                  background-color: #f0f0f0;
                }
              }
            }
          
            .pagination-controls {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 20px;
          
              button {
                padding: 8px 12px;
                margin: 0 5px;
                border: none;
                background-color: #007acc;
                color: white;
                border-radius: 4px;
                transition: background-color 0.3s;
          
                &:disabled {
                  background-color: #ccc;
                  cursor: not-allowed;
                }
          
                &:hover:not(:disabled) {
                  background-color: #0056b3;
                }
              }
          
              span {
                margin: 0 10px;
                font-size: 14px;
                color: #333;
              }
            }
          }
        }
    }

    .daily-report-production{
        .controls-card {
          padding: 16px;
          background-color: #f9f9f9;
          border-radius: 8px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          gap: 20px;
      
          .select-dropdown {
            padding: 10px;
            border: 1px solid #007bff;
            border-radius: 8px;
            font-size: 14px;
            color: #333;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: border-color 0.3s ease;
            width: 230px;
      
            &:hover {
              border-color: #0056b3;
            }
          }

          
      
          input[type="date"] {
            padding: 10px;
            border: 1px solid #007bff;
            border-radius: 8px;
            font-size: 14px;
            color: #333;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: border-color 0.3s ease;
            width: 200px;
      
            &:focus {
              border-color: #0056b3;
              outline: none;
            }
          }
      
          button {
            padding: 6px 15px;
            height: 36px; 
            cursor: pointer;
            border: none;
            background-color: #007acc;
            color: white;
            border-radius: 4px;
            display: flex;
            align-items: center;
            transition: background-color 0.3s;
            font-size: 12px;
      
            .excel-icon {
              margin-right: 5px;
            }
      
            &:hover {
              background-color: #0056b3;
            }
          }
        }
      
        .table-container {
          padding: 15px;
          background: #fff;
          border-radius: 8px;
          border: 1px solid #ccc;
          width: 90%;
          margin: 0 auto;
      
          .table-title {
            margin-bottom: 10px;
            padding: 8px;
            background-color: #007acc;
            color: white;
            text-align: center;
            border-radius: 4px;
            font-weight: bold;
            border: 1px solid #007bff;
          }
      
          table {
            width: 100%;
            border-collapse: collapse;
      
            th,
            td {
              padding: 12px;
              border: 1px solid #ccc;
              text-align: center;
              font-size: 12px;
            }
      
            thead th {
              background-color: #007acc;
              color: white;
              font-weight: bold;
            }
      
            tfoot td {
              font-weight: bold;
              background-color: #f0f0f0;
            }
          }
        }
      
        .pagination-controls {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
      
          button {
            padding: 8px 12px;
            margin: 0 5px;
            border: none;
            background-color: #007acc;
            color: white;
            border-radius: 4px;
            transition: background-color 0.3s;
      
            &:disabled {
              background-color: #ccc;
              cursor: not-allowed;
            }
      
            &:hover:not(:disabled) {
              background-color: #0056b3;
            }
          }
      
          span {
            margin: 0 10px;
            font-size: 14px;
            color: #333;
          }
        }
      }
          
.dashboard .log-report {
    .controls-card {
        display: flex;
        justify-content:left;
        align-items: center;
        padding: 15px;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        gap:20px;

        .select-dropdown {
            padding: 10px;
            border: 1px solid #007bff;
            border-radius: 8px;
            font-size: 14px;
            color: #333;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: border-color 0.3s ease;
            width: 250px;
      
            &:hover {
              border-color: #0056b3;
            }
          }

        input[type="date"] {
            padding: 10px;
            border: 1px solid #007bff;
            border-radius: 8px;
            font-size: 14px;
            color: #333;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: border-color 0.3s ease, box-shadow 0.3s ease;
            width: 200px;
        
            &::placeholder {
                color: #aaa;
                font-style: italic;
            }
        
            &:focus {
                border-color: #0056b3;
                outline: none;
                box-shadow: 0 0 6px rgba(0, 86, 179, 0.5);
                background-color: #e0f7fa; /* Lighter background on focus for better visual feedback */
            }
        
            &:hover {
                border-color: #0056b3;
                box-shadow: 0 2px 6px rgba(0, 86, 179, 0.2); /* Slightly more pronounced shadow on hover */
            }
        
            &:active {
                border-color: #004494; /* Darker border when active for a pressing effect */
                box-shadow: 0 1px 3px rgba(0, 68, 148, 0.4); /* Even lighter shadow when active */
            }
        
            &:disabled {
                background-color: #e9ecef;
                border-color: #ced4da;
                cursor: not-allowed;
            }
        }
        

        button {
            padding: 8px 15px;
            cursor: pointer;
            border: none;
            background-color: #007bff;
            color: white;
            border-radius: 4px;
            display: flex;
            align-items: center;
            transition: background-color 0.3s;

            .excel-icon {
                margin-right: 5px;
            }

            &:hover {
                background-color: #0056b3;
            }
        }
    }

    .table-container {
        padding: 15px;
        background: #fff;
        border-radius: 8px;
        border: 1px solid #ccc;
        width: 100%;
        margin: 0 auto;

        .table-title {
            margin-bottom: 10px;
            padding: 8px;
            background-color: #007acc;
            color: white;
            text-align: center;
            border-radius: 4px;
            font-weight: bold;
            border: 1px solid #007bff;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            max-width: 100%;

            th,
            td {
                padding: 12px;
                border: 1px solid #ccc;
                text-align: center;
            }

            thead th {
                background-color: #007acc;
                color: white;
                font-weight: bold;
            }

            tfoot td {
                font-weight: bold;
                background-color: #f0f0f0;
            }
        }
    }
}
.historical-trend {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .controls-card {
        display: flex;
        justify-content:left;
        align-items: center;
        padding: 15px;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        gap:20px;

        select {
            padding: 10px;
            border: 1px solid #007bff;
            border-radius: 8px;
            font-size: 14px;
            color: #333;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: border-color 0.3s ease;
            width: 200px;

            &:focus {
                border-color: #0056b3;
                outline: none;
            }
        }

        .select-dropdown {
            padding: 10px;
            border: 1px solid #007bff;
            border-radius: 8px;
            font-size: 14px;
            color: #333;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: border-color 0.3s ease;
            width: 250px;
          }
          
          .select-dropdown:hover {
            border-color: #0056b3; 
          }
          
          .select-dropdown:focus {
            border-color: #0056b3; 
            outline: none;
          }
        input[type="date"] {
            padding: 10px;
            border: 1px solid #007bff;
            border-radius: 8px;
            font-size: 14px;
            color: #333;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: border-color 0.3s ease;
            width: 200px;

            &:focus {
                border-color: #0056b3;
                outline: none;
            }
        }
        

        button {
            padding: 8px 15px;
            cursor: pointer;
            border: none;
            background-color:#007acc;
            color: white;
            border-radius: 4px;
            display: flex;
            align-items: center;
            transition: background-color 0.3s;

            &:hover {
                background-color: #0056b3;
            }
        }
    }

    .trend-container {
        padding: 15px;
        background: #fff;
        border-radius: 8px;
        border: 1px solid #ccc;
        width: 900px;
        margin: 0 auto;

        .trend-title {
            margin-bottom: 10px;
            padding: 8px;
            background-color: #007acc;
            color: white;
            text-align: center;
            border-radius: 4px;
            font-weight: bold;
            border: 1px solid #007bff;
        }

        .chart-placeholder {
            padding: 20px;
            border: 2px dashed #007bff;
            border-radius: 8px;
            text-align: center;
            margin-top: 10px;

            /* Placeholder for chart component */
            &:empty:before {
                content: "Insert chart component here";
                font-size: 16px;
                color: #555;
            }
        }
    }
}
.peak-timing-report {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .controls-card {
        display: flex;
        justify-content: left;
        align-items: left;
        padding: 15px;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        gap: 20px;

        .select-dropdown {
            padding: 10px;
            border: 1px solid #007bff;
            border-radius: 8px;
            font-size: 14px;
            color: #333;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: border-color 0.3s ease;
            width: 230px;
          }
          
          .select-dropdown:hover {
            border-color: #0056b3; 
          }
          
          .select-dropdown:focus {
            border-color: #0056b3;
            outline: none;
          }
        input[type="date"] {
            padding: 5px;
            height: 30px;
            border: 1px solid #007bff;
            border-radius: 8px;
            font-size: 14px;
            color: #333;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: border-color 0.3s ease;
            width: 200px;
            margin-top: 5px;

            &:focus {
                border-color: #0056b3;
                outline: none;
            }
        }

        button {
            padding: 6px 12px;
            height:32px;
            cursor: pointer;
            border: none;
            background-color: #007acc;
            color: white;
            border-radius: 4px;
            display: flex;
            align-items: center;
            transition: background-color 0.3s;
            margin-top: 9px;

            .excel-icon {
                margin-right: 5px;
            }

            &:hover {
                background-color: #0056b3;
            }
        }
    }

    .table-container {
        padding: 15px;
        background: #fff;
        border-radius: 8px;
        border: 1px solid #ccc;
        width: 90%;
        margin: 0 auto;

        .table-title {
            margin-bottom: 10px;
            padding: 8px;
            background-color: #007acc;
            color: white;
            text-align: center;
            border-radius: 4px;
            font-weight: bold;
            border: 1px solid #007bff;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            max-width: 100%; 

            th,
            td {
                padding: 12px;
                border: 1px solid #ccc;
                text-align: center;
                font-size: 12px;
            }

            thead th {
                background-color: #007acc;
                color: white;
                font-weight: bold;
            }

            tfoot td {
                font-weight: bold;
                background-color: #f0f0f0;
            }
        }
    }
}
.Parametric-Report {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .controls-card {
        display: flex;
        justify-content: left;
        align-items: left;
        padding: 15px;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        gap: 20px;

        .select-dropdown {
            padding: 10px;
            border: 1px solid #007bff;
            border-radius: 8px;
            font-size: 14px;
            color: #333;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: border-color 0.3s ease;
            width: 230px;

            &:hover {
                border-color: #0056b3;
            }
        }


        input[type="datetime-local"] {
            padding: 5px;
            height: 30px;
            border: 1px solid #007bff;
            border-radius: 8px;
            font-size: 14px;
            color: #333;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: border-color 0.3s ease;
            width: 200px;
            margin-top: 8px;

            &:focus {
                border-color: #0056b3;
                outline: none;
            }
        }
        

        button {
            padding: 6px 12px;
            height: 38px;
            cursor: pointer;
            border: none;
            background-color: #007acc;
            color: white;
            border-radius: 4px;
            display: flex;
            align-items: center;
            transition: background-color 0.3s;
            margin-top: 10px;

            .excel-icon {
                margin-right: 5px;
            }

            &:hover {
                background-color: #0056b3;
            }
        }
    }

    .table-container {
        padding: 15px;
        background: #fff;
        border-radius: 8px;
        border: 1px solid #ccc;
        width: 90%;
        margin: 0 auto;

        .table-title {
            margin-bottom: 10px;
            padding: 8px;
            background-color: #007acc;
            color: white;
            text-align: center;
            border-radius: 4px;
            font-weight: bold;
            border: 1px solid #007bff;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            max-width: 100%; 

            th,
            td {
                padding: 12px;
                border: 1px solid #ccc;
                text-align: center;
                font-size: 12px;
            }

            thead th {
                background-color: #007acc;
                color: white;
                font-weight: bold;
            }

            tfoot td {
                font-weight: bold;
                background-color: #f0f0f0;
            }
        }
    }
}
.Energy-Data {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .controls-card {
        display: flex;
        justify-content: left;
        align-items: left;
        padding: 15px;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        gap: 20px;
        
        input[type="month"] {
            padding: 10px;
            border: 1px solid #007bff;
            border-radius: 8px;
            font-size: 14px;
            color: #333;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: border-color 0.3s ease;
            width: 200px;

            &:focus {
                border-color: #0056b3;
                outline: none;
            }
        }
        

        button {
            padding: 8px 15px;
            cursor: pointer;
            border: none;
            background-color: #007acc;
            color: white;
            border-radius: 4px;
            display: flex;
            align-items: center;
            transition: background-color 0.3s;

            .excel-icon {
                margin-right: 5px;
            }

            &:hover {
                background-color: #0056b3;
            }
        }
    }

    .table-container {
        padding: 15px;
        background: #fff;
        border-radius: 8px;
        border: 1px solid #ccc;
        width: 90%;
        margin: 0 auto;

        .table-title {
            margin-bottom: 10px;
            padding: 8px;
            background-color: #007acc;
            color: white;
            text-align: center;
            border-radius: 4px;
            font-weight: bold;
            border: 1px solid #007bff;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            max-width: 100%; 

            th,
            td {
                padding: 12px;
                border: 1px solid #ccc;
                text-align: center;
                font-size: 12px;
            }

            thead th {
                background-color: #007acc;
                color: white;
                font-weight: bold;
            }

            tfoot td {
                font-weight: bold;
                background-color: #f0f0f0;
            }
        }
    }
}
.Monthly-Report {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .controls-card {
        display: flex;
        justify-content: left;
        align-items: center; 
        padding: 15px;
        background-color: #f9f9f9;
        // border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        gap: 20px;

        .select-dropdown {
            padding: 10px;
            border: 1px solid #007bff;
            border-radius: 8px;
            font-size: 14px;
            color: #333;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: border-color 0.3s ease;
            width: 250px;

            &:hover {
                border-color: #0056b3;
            }
        }

        input[type="datetime-local"] {
            padding: 4px; 
            height: 32px; 
            border: 1px solid #007bff;
            border-radius: 8px;
            font-size: 14px;
            color: #333;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: border-color 0.3s ease;
            width: 200px;

            &:focus {
                border-color: #0056b3;
                outline: none;
            }
        }

        button {
            padding: 6px 12px;
            height: 36px; 
            cursor: pointer;
            border: none;
            background-color: #007acc;
            color: white;
            border-radius: 4px;
            display: flex;
            align-items: center;
            transition: background-color 0.3s;

            .excel-icon {
                margin-right: 5px;
            }

            &:hover {
                background-color: #0056b3;
            }
        }
    }

    .table-container {
        padding: 15px;
        background: #fff;
        border-radius: 8px;
        border: 1px solid #ccc;
        width: 90%;
        margin: 0 auto;

        .table-title {
            margin-bottom: 10px;
            padding: 8px;
            background-color: #007acc;
            color: white;
            text-align: center;
            border-radius: 4px;
            font-weight: bold;
            border: 1px solid #007bff;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            max-width: 100%;

            th,
            td {
                padding: 12px;
                border: 1px solid #ccc;
                text-align: center;
                font-size: 12px;
            }

            thead th {
                background-color: #007acc;
                color: white;
                font-weight: bold;
            }

            tfoot td {
                font-weight: bold;
                background-color: #f0f0f0;
            }
        }
    }
}

.spinner {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db; 
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: spin 1s linear infinite;
    display: inline-block;
    margin-right: 5px; 
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.custom-date-picker {
    padding: 10px;
            border: 1px solid #007bff;
            border-radius: 8px;
            font-size: 14px;
            color: #333;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: border-color 0.3s ease;
            width: 200px;
}
