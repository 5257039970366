
.main-card {
    margin: 40px auto; 
    padding: 30px;
    background-color: #f8f9fa; 
    border-radius: 15px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
    width: 70%;
    max-width: 1000px; 
    margin-bottom: 50px;
  }
.profile-card {
    margin-top: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  
    .profile-heading {
        background-color: #007acc;
        color: white;
        align-content: center;
        font-size: 14px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        margin-bottom: 20px;
      }
  
  
  .profile-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .profile-info {
    width: 70%;
  }
  
  .info-item {
    margin-bottom: 15px;
    width:60%;
    margin-left: 130px;
  }
  
  .info-item label {
    font-size: 14px;
    color: #333;
  }
  
  .info-item input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .user-logo {
    width: 20%;
    text-align: center;
    margin-right: 200px;
  }
  
  .user-initial-logo {
    width: 300px;
    height: 300px;
    // background-color: #007acc;
    color: black;
    font-size:700%;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -70px;
  }
  .update-btn {
    // width: 100%;
    padding: 10px;
    background-color: #007acc;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.update-btn:hover {
    background-color: #005f99;
}

.reset-password-details {
    display: flex;
    justify-content: center; 
    align-items: center;      
    flex-direction: column;   
    width: 100%;            
  }
  
  .reset-password-info {
    width: 100%;
    max-width: 600px;  
    display: flex;
    flex-direction: column;
    align-items: center;   
    justify-content: center; 
    margin-right: 180px;
  }
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-left-color: white; 
    border-radius: 50%;
    width: 12px; 
    height: 12px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  .success-message {
    margin-top: 10px;
    padding: 10px;
    background-color: #d4edda; /* Light green background */
    color: #155724; /* Dark green text */
    border: 1px solid #c3e6cb; /* Green border */
    border-radius: 5px;
  }
  
  .error-message {
    margin-top: 10px;
    padding: 10px;
    background-color: #f8d7da; /* Light red background */
    color: #721c24; /* Dark red text */
    border: 1px solid #f5c6cb; /* Red border */
    border-radius: 5px;
  }