.header {
    background-color: #007acc;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

.header__heading {
    flex: 1; 
    text-align: center;
    margin-left: 220px;
}

.header__heading h1 {
    font-size: 16px;
    margin: 0;
}

.header__icons {
    display: flex;
    gap: 20px; 
    align-items: center;
    margin-right: 40px;
}

.header__icon {
    font-size: 20px;
    cursor: pointer;
}

.header__user-info {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 10px; 
    margin: 0 auto; 
}

.header__avatar-circle {
    background-color: #f39c12;
    color: white;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
}

.header__dropdown-username {
    font-size: 11px;
    color: white;
    font-weight: bold;
    text-align: center;
    margin-top: 5px; 
}

.header__logout-icon {
    font-size: 20px;
    color: #fff;
    transition: color 0.3s ease;

    &:hover {
        color: #007acc;
    }
}

.header__profile-section {
    position: relative;
    display: flex;
    align-items: center; 
    gap: 10px; 
    cursor: pointer;

    .header__clickable {
        cursor: pointer;
        font-size: 20px;
        transition: all 0.3s ease;
    }

    .header__dropdown {
        position: absolute;
        right: -35%;
        top: 110%;
        background: #fff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 20px;
        z-index: 10;
        width: 140px; 
        border: 1px solid #ddd;
        white-space: nowrap; 
        overflow: hidden; 
        text-overflow: ellipsis;
    }
    

        .header__dropdown-item {
            display: flex;
            align-items: center; 
            padding: 8px 12px;
            cursor: pointer;
            color: #333;

            &:hover {
                background: #f0f0f0;
            }

            .header__avatar-circle {
                width: 40px;
                height: 40px;
                background: #f39c12;
                color: white;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5px; 
            }

            .header__dropdown-username {
                font-weight: bold;
                text-align: left; 
                color: black;
            }

            .header__icon {
                margin-right: 10px;
                font-size: 16px;
                color: #333;
            }
        }

        .header__profile {
            .header__icon {
                margin-right: 10px;
            }
        }

        .header__logout {
            .header__icon {
                margin-right: 10px;
            }
        }
    }

