.navigation {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    .nav-item {
      margin: 10px 0;
      cursor: pointer;
      text-align: center;
      transition: all 0.3s ease;
      position: relative; // For dropdown positioning

      .nav-link {
        display: block;
        padding: 10px 20px;
        border-radius: 30px;
        background-color: #f0f0f0;
        color: #333;
        text-decoration: none;
        transition: background-color 0.3s ease, padding 0.3s ease;

        &:hover {
          background-color: #007bff;
          color: white;
        }
      }

      &.expanded .nav-link {
        background-color: #007bff;
        color: white;
        padding: 15px 40px; // Expand padding when active
      }

      // Dropdown menu styles
      .dropdown {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #ffffff;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        z-index: 1000;
        padding: 10px 0;

        .dropdown-item {
          padding: 10px 20px;
          text-align: left;
          color: #333;
          text-decoration: none;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #007bff;
            color: white;
          }
        }
      }

      &:hover .dropdown {
        display: block; 
      }
    }
  }
}
