.enhanced-spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    position: fixed;
    top: -170px;
    left: 0;
    width: 100%;
    z-index: 999;
}

.enhanced-spinner {
    display: flex; 
    justify-content: space-around;
    align-items: center;
    width: 300px; 
}

.dot {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #007acc;
    animation: bounce 0.6s infinite alternate;
}

.dot:nth-child(1) {
    background-color: #ff5733; /* Red */
}

.dot:nth-child(2) {
    background-color: #33ff57; /* Green */
    animation-delay: 0.1s;
}

.dot:nth-child(3) {
    background-color: #3357ff; /* Blue */
    animation-delay: 0.2s;
}

.dot:nth-child(4) {
    background-color: #f5b041; /* Orange */
    animation-delay: 0.3s;
}

.dot:nth-child(5) {
    background-color: #9b59b6; /* Purple */
    animation-delay: 0.4s;
}

@keyframes bounce {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(15px); /* Move horizontally */
    }
}