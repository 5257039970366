.layout-container {
  display: flex;
  transition: background-color 0.3s ease;
  position: relative;
  width: 100%;
  // height: 100vh; /* Ensures it spans the entire viewport */
}

.app-sidebar {
  color: white;
  transition: width 0.3s ease, transform 0.3s ease;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 0; /* Default collapsed state */
  overflow: hidden;
  background-color: #343a40; /* Sidebar background color */
  z-index: 2000;

  &.visible {
    width: 200px; /* Expanded state */
    transform: translateX(0);
  }

  &.hidden {
    transform: translateX(-200px); 
  }
}

.main-content {
  flex: 1;
  transition: margin-left 0.3s ease, padding 0.3s ease;
  background-color: ghostwhite;
  // padding: 20px; 
  &.active {
    margin-left: 200px;
  }
}

.sidebar-toggler {
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  background-color: #057efff3;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 3000; 

  &.rotate {
    transform: translateX(-50%) rotate(180deg); 
  }

  &:hover {
    background-color: #0056b3;
  }
}


@media (max-width: 768px) {
  .app-sidebar {
    width: 0;
    &.visible {
      width: 150px; 
    }
  }

  .main-content {
    padding: 10px;
    &.active {
      margin-left: 150px;
    }
  }
}
