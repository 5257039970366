@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  .live-background {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(45deg, #6a11cb, #2575fc, #ff6f61);
    background-size: 300% 300%;
    animation: gradientAnimation 10s ease infinite;
  }
  