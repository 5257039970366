/* styles.scss */
@import 'colors';

body {
    background-color: white;
}

header, footer {
    background-color: $primary-color;
}

.button {
    background-color: $primary-color;
    color: white;
}
